import { Box, Button, Radio, TextField, radio } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";

const Form = () => {
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const handleFormSubmit = (values) => {
        console.log(values);
    };

    return (
        <Box m="20px">
            <Header title="CREATE AGENT" subtitle="Create a New Agent" />

            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <h5>Agent Details</h5>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.CName}
                                name="CName"
                                error={!!touched.CName && !!errors.CName}
                                helperText={touched.CName && errors.CName}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Company Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.CName}
                                name="CName"
                                error={!!touched.CName && !!errors.CName}
                                helperText={touched.CName && errors.CName}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Bank Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.CName}
                                name="CName"
                                error={!!touched.CName && !!errors.CName}
                                helperText={touched.CName && errors.CName}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Branch Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.CName}
                                name="CName"
                                error={!!touched.CName && !!errors.CName}
                                helperText={touched.CName && errors.CName}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Account Number"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.CName}
                                name="CName"
                                error={!!touched.CName && !!errors.CName}
                                helperText={touched.CName && errors.CName}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Phone Number"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.contact}
                                name="contact"
                                error={!!touched.contact && !!errors.contact}
                                helperText={touched.contact && errors.contact}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="email"
                                label="Email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.companymail}
                                name="contact"
                                error={!!touched.companymail && !!errors.companymail}
                                helperText={touched.companymail && errors.companymail}
                                sx={{ gridColumn: "span 2" }}
                            />

                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="NID No."
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.companyaddress}
                                name="companyaddress"
                                error={!!touched.companyaddress && !!errors.companyaddress}
                                helperText={touched.companyaddress && errors.companyaddress}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Address"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.companyaddress}
                                name="companyaddress"
                                error={!!touched.companyaddress && !!errors.companyaddress}
                                helperText={touched.companyaddress && errors.companyaddress}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="file"
                                // label="Company Logo"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.companylogo}
                                name="companylogo"
                                error={!!touched.companylogo && !!errors.companylogo}
                                helperText={touched.companylogo && errors.companylogo}
                                sx={{ gridColumn: "span 2" }}
                            />
                        </Box>
                        <h5 className="mt-3">Agent Documents</h5>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >

                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="File Title"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.companyaddress}
                                name="companyaddress"
                                error={!!touched.companyaddress && !!errors.companyaddress}
                                helperText={touched.companyaddress && errors.companyaddress}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="file"
                                // label="Company Logo"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.companylogo}
                                name="companylogo"
                                error={!!touched.companylogo && !!errors.companylogo}
                                helperText={touched.companylogo && errors.companylogo}
                                sx={{ gridColumn: "span 2" }}
                            />

                        </Box>
                        <h5 className="mt-3">Status</h5>
                        <Radio
                            checked={values === 'a'}
                            onChange={handleChange}
                            values="a"
                            name="radio-buttons"
                            slotProps={{ input: { 'aria-label': 'A' } }}
                        /> Active
                        <Radio
                            checked={values === 'a'}
                            onChange={handleChange}
                            values="a"
                            name="radio-buttons"
                            slotProps={{ input: { 'aria-label': 'A' } }}
                        /> Inactive
                        <Box display="flex" justifyContent="end" mt="20px">
                            <Button type="submit" color="secondary" variant="contained">
                                Create New Visitor
                            </Button>&nbsp;&nbsp;
                            <Button type="submit" color="primary" variant="contained">
                                Reset
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    );
};

const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
    CName: yup.string().required("required"),
    companymail: yup.string().email("invalid email").required("required"),
    contact: yup
        .string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required("required"),
    companyaddress: yup.string().required("required"),
    companylogo: yup.string().required("required"),
});
const initialValues = {
    CName: "",
    contact: "",
    companyaddress: "",
    companylogo: "",
    companymail: "",
};

export default Form;
