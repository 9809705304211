import { Box, Button, TextField, Select, MenuItem, InputLabel, FormControl, FormHelperText } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";

const Form = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleFormSubmit = (values) => {
    console.log(values);
  };

  return (
    <Box m="20px">
      <Header title="PASSPORT PROCESSING" subtitle="Create Passport" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <FormControl fullWidth variant="filled" error={!!touched.VisitingPurpose && !!errors.VisitingPurpose} sx={{ gridColumn: "span 2" }}>
                <InputLabel id="visiting-purpose-label">Select Agent</InputLabel>
                <Select
                  labelId="visiting-purpose-label"
                  value={values.VisitingPurpose}
                  onChange={handleChange}
                  name="VisitingPurpose"
                  onBlur={handleBlur}
                  label="Visiting Purpose" // Ensure the label prop matches the InputLabel text
                >
                  <MenuItem value="a">A</MenuItem>
                  <MenuItem value="b">B</MenuItem>
                  <MenuItem value="c">C</MenuItem>
                </Select>
                {touched.VisitingPurpose && errors.VisitingPurpose && (
                  <FormHelperText>{errors.VisitingPurpose}</FormHelperText>
                )}
              </FormControl>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Full Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Name}
                name="Name"
                error={!!touched.Name && !!errors.Name}
                helperText={touched.Name && errors.Name}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Reference"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.contact}
                name="contact"
                error={!!touched.contact && !!errors.contact}
                helperText={touched.contact && errors.contact}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Father's Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address1}
                name="address1"
                error={!!touched.address1 && !!errors.address1}
                helperText={touched.address1 && errors.address1}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Mother's Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address1}
                name="address1"
                error={!!touched.address1 && !!errors.address1}
                helperText={touched.address1 && errors.address1}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Phone Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address1}
                name="address1"
                error={!!touched.address1 && !!errors.address1}
                helperText={touched.address1 && errors.address1}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Emergency Contact No"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address1}
                name="address1"
                error={!!touched.address1 && !!errors.address1}
                helperText={touched.address1 && errors.address1}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Passport No"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address1}
                name="address1"
                error={!!touched.address1 && !!errors.address1}
                helperText={touched.address1 && errors.address1}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Date of Birth"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address1}
                name="address1"
                error={!!touched.address1 && !!errors.address1}
                helperText={touched.address1 && errors.address1}
                sx={{ gridColumn: "span 2" }}
              />
              <FormControl fullWidth variant="filled" error={!!touched.AssignUser && !!errors.AssignUser} sx={{ gridColumn: "span 2" }}>
                <InputLabel id="assign-user-label">Passport Type</InputLabel>
                <Select
                  labelId="assign-user-label"
                  value={values.AssignUser}
                  onChange={handleChange}
                  name="AssignUser"
                  onBlur={handleBlur}
                >
                  <MenuItem value="a">A</MenuItem>
                  <MenuItem value="b">B</MenuItem>
                  <MenuItem value="c">C</MenuItem>
                </Select>
                {touched.AssignUser && errors.AssignUser && (
                  <FormHelperText>{errors.AssignUser}</FormHelperText>
                )}
              </FormControl>
              <TextField
                fullWidth
                variant="filled"
                label="Present Address"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Remarks}
                name="Remarks"
                error={!!touched.Remarks && !!errors.Remarks}
                helperText={touched.Remarks && errors.Remarks}
                multiline// Adjust based on how many rows you want
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Permanent Address"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Remarks}
                name="Remarks"
                error={!!touched.Remarks && !!errors.Remarks}
                helperText={touched.Remarks && errors.Remarks}
                multiline// Adjust based on how many rows you want
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Fee Amount"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Remarks}
                name="Remarks"
                error={!!touched.Remarks && !!errors.Remarks}
                helperText={touched.Remarks && errors.Remarks}
                multiline// Adjust based on how many rows you want
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Service Charge"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Remarks}
                name="Remarks"
                error={!!touched.Remarks && !!errors.Remarks}
                helperText={touched.Remarks && errors.Remarks}
                multiline// Adjust based on how many rows you want
                sx={{ gridColumn: "span 2" }}
              />

            </Box>
            <h5 className="mt-3">Additional Documents</h5>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="File Title"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.companyaddress}
                name="companyaddress"
                error={!!touched.companyaddress && !!errors.companyaddress}
                helperText={touched.companyaddress && errors.companyaddress}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="file"
                // label="Company Logo"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.companylogo}
                name="companylogo"
                error={!!touched.companylogo && !!errors.companylogo}
                helperText={touched.companylogo && errors.companylogo}
                sx={{ gridColumn: "span 2" }}
              />

            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Submit
              </Button>&nbsp;&nbsp;
              <Button type="submit" color="primary" variant="contained">
                Reset
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  Name: yup.string().required("required"),
  lastName: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  contact: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("required"),
  address1: yup.string().required("required"),
  VisitingPurpose: yup.string().required("required"),
  AssignUser: yup.string().required("required"),
  Remarks: yup.string().required("required"),
});
const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  contact: "",
  address1: "",
  VisitingPurpose: "",
  AssignUser: "",
  Remarks: "",
};

export default Form;
