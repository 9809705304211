import { Box, Button, TextField, Select, MenuItem, InputLabel, FormControl, FormHelperText, Radio } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";

const Form = () => {
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const handleFormSubmit = (values) => {
        console.log(values);
    };

    return (
        <Box m="20px">
            <Header title="TICKETING AIRLINES" subtitle="Create Ticketing Airlines" />

            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Airline Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.CName}
                                name="CName"
                                error={!!touched.CName && !!errors.CName}
                                helperText={touched.CName && errors.CName}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Airline Code"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.CName}
                                name="CName"
                                error={!!touched.CName && !!errors.CName}
                                helperText={touched.CName && errors.CName}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Ticketing Serial"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.CName}
                                name="CName"
                                error={!!touched.CName && !!errors.CName}
                                helperText={touched.CName && errors.CName}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Ticketing Class"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.CName}
                                name="CName"
                                error={!!touched.CName && !!errors.CName}
                                helperText={touched.CName && errors.CName}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <FormControl fullWidth variant="filled" error={!!touched.VisitingPurpose && !!errors.VisitingPurpose} sx={{ gridColumn: "span 2" }}>
                                <InputLabel id="visiting-purpose-label">Visiting Purpose</InputLabel>
                                <Select
                                    labelId="visiting-purpose-label"
                                    value={values.VisitingPurpose}
                                    onChange={handleChange}
                                    name="VisitingPurpose"
                                    onBlur={handleBlur}
                                    label="Visiting Purpose" // Ensure the label prop matches the InputLabel text
                                >
                                    <MenuItem value="a">A</MenuItem>
                                    <MenuItem value="b">B</MenuItem>
                                    <MenuItem value="c">C</MenuItem>
                                </Select>
                                {touched.VisitingPurpose && errors.VisitingPurpose && (
                                    <FormHelperText>{errors.VisitingPurpose}</FormHelperText>
                                )}
                            </FormControl>
                        </Box>

                        <h5 className="mt-3">Status</h5>
                        <Radio
                            checked={values === 'a'}
                            onChange={handleChange}
                            values="a"
                            name="radio-buttons"
                            slotProps={{ input: { 'aria-label': 'A' } }}
                        /> Active
                        <Radio
                            checked={values === 'a'}
                            onChange={handleChange}
                            values="a"
                            name="radio-buttons"
                            slotProps={{ input: { 'aria-label': 'A' } }}
                        /> Inactive
                        <Box display="flex" justifyContent="end" mt="20px">
                            <Button type="submit" color="secondary" variant="contained">
                                Submit
                            </Button>&nbsp;&nbsp;
                            <Button type="submit" color="primary" variant="contained">
                                Reset
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    );
};

const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
    CName: yup.string().required("required"),
    companymail: yup.string().email("invalid email").required("required"),
    contact: yup
        .string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required("required"),
    companyaddress: yup.string().required("required"),
    companylogo: yup.string().required("required"),
});
const initialValues = {
    CName: "",
    contact: "",
    companyaddress: "",
    companylogo: "",
    companymail: "",
};

export default Form;
