import { Box, Button, Typography, useTheme } from "@mui/material";
// import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
// import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Link } from 'react-router-dom';

const Team = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const columns = [
        { field: "id", headerName: "ID" },
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            cellClassName: "name-column--cell",
        },
        {
            field: "age",
            headerName: "Age",
            type: "number",
            headerAlign: "left",
            align: "left",
        },
        {
            field: "phone",
            headerName: "Phone Number",
            flex: 1,
        },
        {
            field: "email",
            headerName: "Email",
            flex: 1,
        },
        {
            field: "accessLevel",
            headerName: "Access Level",
            flex: 1,
            renderCell: ({ row: { access } }) => {
                return (
                    <Box
                        width="60%"
                        m="0 auto"
                        p="5px"
                        display="flex"
                        justifyContent="center"
                        backgroundColor={
                            access === "admin"
                                ? colors.greenAccent[600]
                                : access === "manager"
                                    ? colors.greenAccent[700]
                                    : colors.greenAccent[700]
                        }
                        borderRadius="4px"
                    >
                        {access === "admin" && <AdminPanelSettingsOutlinedIcon />}
                        {access === "manager" && <SecurityOutlinedIcon />}
                        {access === "user" && <LockOpenOutlinedIcon />}
                        <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
                            {access}
                        </Typography>
                    </Box>
                );
            },
        },
    ];

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="AGENT" subtitle="Agent List" />

                <Box>
                    <Button
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }}
                    >
                        <Link to="/createagent" className="text-decoration-none text-white">
                            + Create Agent
                        </Link>
                    </Button>
                </Box>
            </Box>
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                }}
            >
                {/* <DataGrid checkboxSelection rows={mockDataTeam} columns={columns} /> */}
                <div class="table-responsive">
                    <table class="table accordion table-dark">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">Picture</th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Phone Number</th>
                                <th scope="col">NID No</th>
                                <th scope="col">Company Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr data-bs-toggle="collapse" data-bs-target="#r1">
                                <th scope="row">+</th>
                                <td> <img
                                    alt="logo"
                                    width="50px"
                                    height="50px"
                                    src={`../../assets/favicon.ico`}
                                /></td>
                                <td>Mohammad Ansari</td>
                                <td>lorem@ipsum.com</td>
                                <td>1235690</td>
                                <td>123df90</td>
                                <td>Aperiam aut</td>
                            </tr>
                            <tr class="collapse accordion-collapse" id="r1" data-bs-parent=".table">
                                <td colspan="7"><b>Bank Name:</b> Lorem Ipsum<br />
                                    <b> Branch Name:</b> Lorem ipsum<br />
                                    <b>Account No:</b> 123456<br />
                                    <b>Address:</b> 123 lorem ipsum, wasabi<br />
                                    <b>User Level:</b> Agent<br />
                                    <b>Status:</b> <Button type="submit" color="secondary" variant="contained"><b>Active</b></Button>  <br />
                                    <b>Actions:</b> <EditIcon />&nbsp; <RemoveRedEyeIcon /><br />
                                </td>
                            </tr>
                            <tr data-bs-toggle="collapse" data-bs-target="#r2">
                                <th scope="row">+</th>
                                <td> <img
                                    alt="logo"
                                    width="50px"
                                    height="50px"
                                    src={`../../assets/favicon.ico`}
                                /></td>
                                <td>Mohammad Ansari</td>
                                <td>lorem@ipsum.com</td>
                                <td>1235690</td>
                                <td>123df90</td>
                                <td>Aperiam aut</td>
                            </tr>
                            <tr class="collapse accordion-collapse" id="r2" data-bs-parent=".table">
                                <td colspan="7"><b>Bank Name:</b> Lorem Ipsum<br />
                                    <b> Branch Name:</b> Lorem ipsum<br />
                                    <b>Account No:</b> 123456<br />
                                    <b>Address:</b> 123 lorem ipsum, wasabi<br />
                                    <b>User Level:</b> Agent<br />
                                    <b>Status:</b> <Button type="submit" color="secondary" variant="contained"><b>Active</b></Button>  <br />
                                    <b>Actions:</b> <EditIcon />&nbsp; <RemoveRedEyeIcon /><br />
                                </td>
                            </tr>
                            <tr data-bs-toggle="collapse" data-bs-target="#r3">
                                <th scope="row">+</th>
                                <td> <img
                                    alt="logo"
                                    width="50px"
                                    height="50px"
                                    src={`../../assets/favicon.ico`}
                                /></td>
                                <td>Mohammad Ansari</td>
                                <td>lorem@ipsum.com</td>
                                <td>1235690</td>
                                <td>123df90</td>
                                <td>Aperiam aut</td>
                            </tr>
                            <tr class="collapse accordion-collapse" id="r3" data-bs-parent=".table">
                                <td colspan="7"><b>Bank Name:</b> Lorem Ipsum<br />
                                    <b> Branch Name:</b> Lorem ipsum<br />
                                    <b>Account No:</b> 123456<br />
                                    <b>Address:</b> 123 lorem ipsum, wasabi<br />
                                    <b>User Level:</b> Agent<br />
                                    <b>Status:</b> <Button type="submit" color="secondary" variant="contained"><b>Active</b></Button>  <br />
                                    <b>Actions:</b> <EditIcon />&nbsp; <RemoveRedEyeIcon /><br />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Box>
        </Box>
    );
};

export default Team;
