import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Visitor from "./scenes/Visitor";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import CreateVisitor from "./scenes/CreateVisitor";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import Userinfo from "./scenes/Userinfo";
import Geography from "./scenes/geography";
import Companyinfo from './scenes/Companyinfo'
import Agentlist from './scenes/Agentlist'
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import CreateAgent from './scenes/CreateAgent'
import Banklist from './scenes/Banklist'
import Addbank from './scenes/Addbank'
import Countrylist from './scenes/Countrylist'
import Addcountry from './scenes/Addcountry'
import Supplierinfo from './scenes/Supplierinfo'
import Addsupplier from './scenes/Addsupplier'
import Projectsetup from './scenes/Projectsetup'
import Marketmanagement from './scenes/Marketmanagement'
import Ticketairlines from './scenes/Ticketairlines'
import Createticketairlines from './scenes/Createticketairlines'
import Ticketpurchase from './scenes/Ticketpurchase'
import Createticketpurchase from './scenes/Createticketpurchase'
import Ticketsales from './scenes/Ticketsales'
import Createticketsales from './scenes/Createticketsales'
import Passportprocessing from './scenes/Passportprocessing'
import Visaprocessing from './scenes/Visaprocessing'
import Createvisaprocessing from './scenes/Createvisaprocessing'

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar isSidebar={isSidebar} />
          <main className="content">
            <Topbar setIsSidebar={setIsSidebar} />
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/visitor" element={<Visitor />} />
              <Route path="/contacts" element={<Contacts />} />
              <Route path="/invoices" element={<Invoices />} />
              <Route path="/createvisitor" element={<CreateVisitor />} />
              <Route path="/bar" element={<Bar />} />
              <Route path="/pie" element={<Pie />} />
              <Route path="/line" element={<Line />} />
              <Route path="/userinfo" element={<Userinfo />} />
              <Route path="/calendar" element={<Calendar />} />
              <Route path="/geography" element={<Geography />} />
              <Route path="/companyinfo" element={<Companyinfo />} />
              <Route path="/agentlist" element={<Agentlist />} />
              <Route path="/createagent" element={<CreateAgent />} />
              <Route path="/banklist" element={<Banklist />} />
              <Route path="/addbank" element={<Addbank />} />
              <Route path="/countrylist" element={<Countrylist />} />
              <Route path="/addcountry" element={<Addcountry />} />
              <Route path="/supplierinfo" element={<Supplierinfo />} />
              <Route path="/addsupplier" element={<Addsupplier />} />
              <Route path="/projectsetup" element={<Projectsetup />} />
              <Route path="/marketmanagement" element={<Marketmanagement />} />
              <Route path="/ticketairlines" element={<Ticketairlines />} />
              <Route path="/createticketairlines" element={<Createticketairlines />} />
              <Route path="/ticketpurchase" element={<Ticketpurchase />} />
              <Route path="/createticketpurchase" element={<Createticketpurchase />} />
              <Route path="/ticketsales" element={<Ticketsales />} />
              <Route path="/createticketsales" element={<Createticketsales />} />
              <Route path="/passportprocessing" element={<Passportprocessing />} />
              <Route path="/visaprocessing" element={<Visaprocessing />} />
              <Route path="/createvisaprocessing" element={<Createvisaprocessing />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
