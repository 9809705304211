import { Box, Button, TextField, Select, MenuItem, InputLabel, FormControl, FormHelperText } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";

const Form = () => {
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const handleFormSubmit = (values) => {
        console.log(values);
    };

    return (
        <Box m="20px">
            <Header title="SEND SMS" subtitle="Send a New SMS" />

            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            <FormControl fullWidth variant="filled" error={!!touched.VisitingPurpose && !!errors.VisitingPurpose} sx={{ gridColumn: "span 4" }}>
                                <InputLabel id="visiting-purpose-label">Service Type</InputLabel>
                                <Select
                                    labelId="visiting-purpose-label"
                                    value={values.VisitingPurpose}
                                    onChange={handleChange}
                                    name="VisitingPurpose"
                                    onBlur={handleBlur}
                                    label="Visiting Purpose" // Ensure the label prop matches the InputLabel text
                                >
                                    <MenuItem value="a">A</MenuItem>
                                    <MenuItem value="b">B</MenuItem>
                                    <MenuItem value="c">C</MenuItem>
                                </Select>
                                {touched.VisitingPurpose && errors.VisitingPurpose && (
                                    <FormHelperText>{errors.VisitingPurpose}</FormHelperText>
                                )}
                            </FormControl>

                            <TextField
                                fullWidth
                                variant="filled"
                                label="Message"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.Remarks}
                                name="Remarks"
                                error={!!touched.Remarks && !!errors.Remarks}
                                helperText={touched.Remarks && errors.Remarks}
                                multiline// Adjust based on how many rows you want
                                sx={{ gridColumn: "span 4" }}
                            />
                        </Box>
                        <Box display="flex" justifyContent="end" mt="20px">
                            <Button type="submit" color="secondary" variant="contained">
                                Send
                            </Button>&nbsp;&nbsp;
                            <Button type="submit" color="primary" variant="contained">
                                Reset
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    );
};

const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
    Name: yup.string().required("required"),
    lastName: yup.string().required("required"),
    email: yup.string().email("invalid email").required("required"),
    contact: yup
        .string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required("required"),
    address1: yup.string().required("required"),
    VisitingPurpose: yup.string().required("required"),
    AssignUser: yup.string().required("required"),
    Remarks: yup.string().required("required"),
});
const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    contact: "",
    address1: "",
    VisitingPurpose: "",
    AssignUser: "",
    Remarks: "",
};

export default Form;
