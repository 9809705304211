import { Box, Button, Typography, useTheme } from "@mui/material";
// import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
// import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import { Link } from 'react-router-dom';

const Team = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "age",
      headerName: "Age",
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "accessLevel",
      headerName: "Access Level",
      flex: 1,
      renderCell: ({ row: { access } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              access === "admin"
                ? colors.greenAccent[600]
                : access === "manager"
                  ? colors.greenAccent[700]
                  : colors.greenAccent[700]
            }
            borderRadius="4px"
          >
            {access === "admin" && <AdminPanelSettingsOutlinedIcon />}
            {access === "manager" && <SecurityOutlinedIcon />}
            {access === "user" && <LockOpenOutlinedIcon />}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {access}
            </Typography>
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="VISITORS" subtitle="Managing the Visitors" />

        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <Link to="/createvisitor" className="text-decoration-none text-white">
              + Create Visitors
            </Link>
          </Button>
        </Box>
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        {/* <DataGrid checkboxSelection rows={mockDataTeam} columns={columns} /> */}
        <div class="table-responsive">
          <table class="table accordion table-dark">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Assign Agent</th>
                <th scope="col">Name</th>
                <th scope="col">Phone</th>
                <th scope="col">Address</th>
              </tr>
            </thead>
            <tbody>
              <tr data-bs-toggle="collapse" data-bs-target="#r1">
                <th scope="row">+</th>
                <td>Mohammad</td>
                <td>Mark otto</td>
                <td>1235690</td>
                <td>Aperiam aut rerum la</td>
              </tr>
              <tr class="collapse accordion-collapse" id="r1" data-bs-parent=".table">
                <td colspan="5"><b>Visting Purpose:</b> Lorem Ipsum<br />
                  <b> Purpose:</b> Lorem ipsum<br />
                  <b>Action:</b>
                </td>
              </tr>
              <tr data-bs-toggle="collapse" data-bs-target="#r2">
                <th scope="row">+</th>
                <td>Mohammad</td>
                <td>Mark otto</td>
                <td>1235690</td>
                <td>Aperiam aut rerum la</td>
              </tr>
              <tr class="collapse accordion-collapse" id="r2" data-bs-parent=".table">
                <td colspan="5"><b>Visting Purpose:</b> Lorem Ipsum<br />
                  <b> Purpose:</b> Lorem ipsum<br />
                  <b>Action:</b>
                </td>
              </tr>
              <tr data-bs-toggle="collapse" data-bs-target="#r3">
                <th scope="row">+</th>
                <td>Mohammad</td>
                <td>Mark otto</td>
                <td>1235690</td>
                <td>Aperiam aut rerum la</td>
              </tr>
              <tr class="collapse accordion-collapse" id="r3" data-bs-parent=".table">
                <td colspan="5"><b>Visting Purpose:</b> Lorem Ipsum<br />
                  <b> Purpose:</b> Lorem ipsum<br />
                  <b>Action:</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Box>
    </Box>
  );
};

export default Team;
